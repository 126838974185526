import { useState, useEffect, useCallback, useRef } from "react";

import { track } from "@amplitude/analytics-browser";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { toast } from "react-toastify";
import usePrompt from "../../../hooks/router";
import * as yup from "yup";

import { IContentData, IGeneratedQuestion } from "../../../types/content";
import Difficulty, {
  LIST as DIFFICULTIES_LIST,
} from "../../../constants/difficulties";

import { FieldArray, FormikProvider, useFormik } from "formik";
import SingleImageUpload from "../../../components/SingleImageUpload";
import QUESTION_STATUSES from "../../../constants/questionStatuses";

import {
  Stack,
  TextField,
  InputAdornment,
  Switch,
  IconButton,
  Typography,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  Radio,
  FormControlLabel,
  Chip,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import Trash from "../../../components/Icons/Trash";
import Plus from "../../../components/Icons/Plus";
import Save from "../../../components/Icons/Save";

import questionSchema from "../../../schemas/question";
import socialQuestionSchema from "../../../schemas/socialQuestion";

import {
  BoldItalicUnderlineToggles,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  toolbarPlugin,
} from "@mdxeditor/editor";
import { listsPlugin } from "@mdxeditor/editor";
import { v4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { openModal } from "../../../features/modals";
import MODALS from "../../../constants/modals";
import { Warning } from "@mui/icons-material";
import InfoCircle from "../../../components/Icons/InfoCircle";
import { setRun } from "../../../features/tour";
import LESSON_TYPE from "../../../constants/lessonType";

interface IQuestionProps {
  questionCategoryId: number;
  questionCategoryEnumId: number;
  question?: IContentData;
  disabled: boolean;
  onDelete: (questionId: number) => void;
  successCallback: (question: IContentData, isNew?: boolean) => void;
  onDirtyChange: (isDirty: boolean) => void;
  topics: string[];
}

const Question: React.FunctionComponent<IQuestionProps> = ({
  questionCategoryId,
  questionCategoryEnumId,
  question,
  disabled,
  onDelete,
  successCallback,
  onDirtyChange,
  topics,
}) => {
  const tour = useAppSelector((state) => state.tour);
  const ref = useRef<MDXEditorMethods>(null);

  const dispatch = useAppDispatch();

  const [uuid, setUuid] = useState<string>(v4());

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isQuestionGenerating, setIsQuestionGenerating] = useState(false);
  const [isQuestionValidToEnable, setIsQuestionValidToEnable] = useState(false);

  const formik = useFormik<IContentData & { formFile?: File }>({
    initialValues: question || {
      lessonType: LESSON_TYPE.Quiz,
      questionId: 0,
      text: "",
      status: 0,
      languageId: 1,
      answers: [],
      orderIndex: 0,
    },
    enableReinitialize: true,
    validationSchema:
      questionCategoryEnumId === 1 ? socialQuestionSchema : questionSchema,
    onSubmit: (values) => {
      if (!values.status || values.status === QUESTION_STATUSES.Invisible) {
        dispatch(
          openModal({
            type: MODALS.Approve,
            title: (
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Warning />
                  </ListItemIcon>
                  <ListItemText>
                    You can save this lesson, but if you don't click “Enable”,
                    nobody will receive the lesson. If no lessons in the course
                    are enabled, you won't be able to schedule this course at
                    all.
                  </ListItemText>
                </ListItem>
              </List>
            ),
            onApprove: () => {
              submitData(values);
            },
          })
        );
      } else {
        submitData(values);
      }
    },
  });

  const submitData = (values: IContentData & { formFile?: File }) => {
    setIsSubmitting(true);

    const formData = serialize(values, {
      allowEmptyArrays: true,
      indices: true,
    });
    if (values.questionId === 0) {
      formData.append("questionCategoryId", questionCategoryId.toString());
      formData.delete("questionId");
    }

    axios
      .post<IContentData>(
        values.questionId === 0
          ? "/Content/CreateQuestion"
          : "/Content/UpdateQuestion",
        formData
      )
      .then((res) => {
        toast("Lesson saved successfully", {
          type: "success",
        });
        successCallback(res.data, values.questionId === 0);
        track("SavedContentQuestion");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleQuestionGenerate = useCallback(() => {
    if (!yup.string().required().isValidSync(formik.values.topic)) {
      formik.setErrors({
        topic: "Topic is required to generate question",
      });
      return;
    }

    formik.setErrors({
      topic: undefined,
    });

    setIsQuestionGenerating(true);

    axios
      .get<IGeneratedQuestion>("/Content/GenerateHubQuiz", {
        params: {
          contentId: questionCategoryId,
          topic: formik.values.topic,
          difficulty: formik.values.difficulty,
        },
      })
      .then((res) => {
        formik.setFieldValue("text", res.data.question);
        formik.setFieldValue("answers", res.data.answers);
        formik.setFieldValue("description", res.data.description);
        setUuid(v4());

        if (tour.index === 7 && tour.run === false) {
          dispatch(setRun(true));
        }
      })
      .catch(() => {
        toast("Failed to generate question", {
          type: "error",
        });
      })
      .finally(() => {
        setIsQuestionGenerating(false);
      });
  }, [questionCategoryId, formik.values.topic, formik.values.difficulty, tour]);

  useEffect(() => {
    setIsQuestionValidToEnable(
      (formik.values.answers !== undefined &&
        formik.values.answers.filter((answer) => answer.text !== "").length >=
          2 &&
        formik.values.answers
          .filter((answer) => answer.text !== "")
          .findIndex((answer) => answer.isCorrect) !== -1 &&
        formik.values.text !== "") ||
        (formik.values.lessonType === LESSON_TYPE.Survey &&
          formik.values.text !== "")
    );
  }, [formik.values]);

  useEffect(() => {
    if (!isQuestionValidToEnable) {
      formik.setFieldValue("status", QUESTION_STATUSES.Invisible);
    }
  }, [isQuestionValidToEnable]);

  usePrompt(
    "You have unsaved changes. Are you sure you want to leave?",
    formik.dirty,
    () => {}
  );

  useEffect(() => {
    onDirtyChange(formik.dirty);
  }, [formik.dirty]);

  useEffect(() => {
    if (question) {
      formik.resetForm(question);
    }
  }, [question]);

  useEffect(() => {
    if (tour.index === 6 && tour.run) {
      formik.setFieldValue("topic", "Time allocation");
      formik.setFieldValue("difficulty", Difficulty.Easy);
    } else if (tour.index === 7 && tour.run === false) {
      formik.setFieldValue("topic", "Time allocation");
      formik.setFieldValue("difficulty", Difficulty.Medium);
      handleQuestionGenerate();
    } else if (tour.index === 8 && tour.run) {
      if (formik.dirty) {
        formik.setFieldValue("status", QUESTION_STATUSES.Visible);
        setTimeout(() => {
          formik.handleSubmit();
        }, 500);
      }
    }
  }, [tour, formik.dirty]);

  return (
    <FormikProvider value={formik}>
      <Stack spacing={2}>
        <Stack>
          <MDXEditor
            className={
              Boolean(formik.errors.text) && formik.touched.text ? "error" : ""
            }
            readOnly={disabled}
            key={`question-${formik.values.questionId}-${uuid}`}
            placeholder="Content"
            markdown={formik.values.text || ""}
            onChange={(value: string) => formik.setFieldValue("text", value)}
            plugins={[
              listsPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <BoldItalicUnderlineToggles />
                    <ListsToggle options={["bullet", "number"]} />
                  </>
                ),
              }),
            ]}
          />
          {Boolean(formik.errors.text) && formik.touched.text && (
            <FormHelperText error={true}>{formik.errors.text}</FormHelperText>
          )}
        </Stack>
        <FormControl fullWidth disabled={disabled}>
          <InputLabel id="difficulty-select-label">Difficulty</InputLabel>
          <Select
            labelId="difficulty-select-label"
            value={formik.values.difficulty}
            label="Difficulty"
            name="difficulty"
            onChange={formik.handleChange}
          >
            {DIFFICULTIES_LIST.map((difficulty) => (
              <MenuItem key={difficulty.value} value={difficulty.value}>
                {difficulty.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          disablePortal
          id="topic"
          options={topics}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Topic (optional)"
              error={formik.errors.topic !== undefined}
              helperText={formik.errors.topic}
            />
          )}
          freeSolo
          value={formik.values.topic || null}
          onChange={(event: any, newValue: string | null) => {
            formik.setFieldValue("topic", newValue);
          }}
          inputValue={formik.values.topic ? formik.values.topic : ""}
          onInputChange={(event, newInputValue) => {
            formik.setFieldValue("topic", newInputValue);
          }}
          disabled={disabled}
        />

        <Stack direction="row" alignItems="center" spacing={2} flex={1}>
          <SingleImageUpload
            label="Image (optional)"
            value={formik.values.formFile || formik.values.imageUrl}
            videoUrl={formik.values.videoUrl}
            onFileDrop={(file) => {
              formik.setFieldValue("formFile", file);
            }}
            onFileDelete={() => {
              formik.setFieldValue("formFile", undefined);
              formik.setFieldValue("imageUrl", "");
              formik.setFieldValue("videoUrl", "");
              formik.setFieldValue("imageDeleted", true);
            }}
            disabled={
              disabled ||
              isSubmitting ||
              Boolean(formik.values.videoUrl) ||
              Boolean(formik.values.contentUrl)
            }
          />
          {!formik.values.imageUrl && !formik.values.formFile && (
            <Typography>or</Typography>
          )}
          {!formik.values.imageUrl && !formik.values.formFile && (
            <TextField
              style={{ flex: 1 }}
              id="videoUrl"
              value={formik.values.videoUrl ? formik.values.videoUrl : ""}
              label="Video (optional)"
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(formik.errors.videoUrl)}
              helperText={formik.errors.videoUrl}
              placeholder="https://youtube.com/ or https://vimeo.com/"
              variant="outlined"
              onChange={formik.handleChange}
              disabled={disabled || Boolean(formik.values.contentUrl)}
            />
          )}
          {!formik.values.imageUrl && !formik.values.formFile && (
            <Typography>or</Typography>
          )}
          {!formik.values.imageUrl && !formik.values.formFile && (
            <TextField
              style={{ flex: 1 }}
              id="contentUrl"
              value={formik.values.contentUrl ? formik.values.contentUrl : ""}
              label="URL (optional)"
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(formik.errors.contentUrl)}
              helperText={formik.errors.contentUrl}
              placeholder="Link to any complementary learning content"
              variant="outlined"
              onChange={formik.handleChange}
              disabled={disabled || Boolean(formik.values.videoUrl)}
            />
          )}
        </Stack>

        {questionCategoryEnumId !== 1 && (
          <FieldArray
            name="answers"
            render={(arrayHelpers) => (
              <Stack spacing={2} sx={{ p: 2 }}>
                {formik.values.answers && formik.values.answers.length > 0 && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Answer options</Typography>

                    <Tooltip title="Similar to surveys, all responses are treated as correct. Use this mode to gather input or feedback without grading answers.">
                      <FormControlLabel
                        labelPlacement="start"
                        sx={{ marginRight: -1 }}
                        control={
                          <Switch
                            checked={
                              formik.values.lessonType === LESSON_TYPE.Survey
                            }
                            onChange={(event) => {
                              formik.setFieldValue(
                                "lessonType",
                                event.target.checked
                                  ? LESSON_TYPE.Survey
                                  : LESSON_TYPE.Quiz
                              );

                              formik.values.answers?.forEach((a, i) => {
                                arrayHelpers.replace(i, {
                                  ...a,
                                  isCorrect: event.target.checked,
                                });
                              });
                            }}
                          />
                        }
                        label="Accept all answers"
                      />
                    </Tooltip>
                  </Stack>
                )}
                {formik.values.answers?.map((answer, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <TextField
                      name={`answers.${index}.text`}
                      label="Answer"
                      disabled={disabled}
                      variant="outlined"
                      value={answer.text}
                      onChange={formik.handleChange}
                      error={
                        // @ts-ignore TODO: fix
                        formik.touched.answers?.[index]?.text &&
                        // @ts-ignore TODO: fix
                        formik.errors.answers?.[index]?.text !== undefined
                      }
                      helperText={
                        // @ts-ignore TODO: fix
                        formik.touched.answers?.[index]?.text
                          ? // @ts-ignore TODO: fix
                            formik.errors.answers?.[index]?.text
                          : undefined
                      }
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Radio
                              disabled={disabled || answer.text === ""}
                              checked={answer.isCorrect}
                              onChange={(e, checked) => {
                                arrayHelpers.replace(index, {
                                  ...answer,
                                  isCorrect: true,
                                });

                                // Clear all other radio buttons
                                formik.values.answers?.forEach((a, i) => {
                                  if (i !== index) {
                                    arrayHelpers.replace(i, {
                                      ...a,
                                      isCorrect: false,
                                    });
                                  }
                                });
                              }}
                            />
                          </InputAdornment>
                        ),
                        readOnly: disabled,
                      }}
                    />
                    {!disabled ? (
                      <Tooltip title="Remove answer">
                        <IconButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Stack>
                ))}
                {!disabled ? (
                  <Stack direction="row" justifyContent="center">
                    <Button
                      onClick={() => {
                        let answersToAdd = 1;
                        if (
                          !formik.values.answers ||
                          formik.values.answers.length === 0
                        ) {
                          answersToAdd = 4;
                          formik.setFieldValue("lessonType", LESSON_TYPE.Quiz);
                        }

                        for (let i = 1; i <= answersToAdd; i++) {
                          arrayHelpers.push({
                            text: "",
                            isCorrect:
                              formik.values.lessonType === LESSON_TYPE.Survey &&
                              answersToAdd === 1,
                          });
                        }
                      }}
                      variant="contained"
                      startIcon={<Plus width={25} height={25} color="#fff" />}
                    >
                      {formik.values.answers && formik.values.answers.length > 0
                        ? "Add an answer option"
                        : "Add answer options"}
                    </Button>
                  </Stack>
                ) : null}
              </Stack>
            )}
          />
        )}
        {formik.values.answers && formik.values.answers.length > 0 && (
          <>
            {questionCategoryEnumId !== 1 && (
              <MDXEditor
                readOnly={disabled}
                key={`explanation-${formik.values.questionId}-${uuid}`}
                placeholder="Post-completion note (optional)"
                markdown={formik.values.description || ""}
                onChange={(value: string) =>
                  formik.setFieldValue("description", value)
                }
                plugins={[
                  listsPlugin(),
                  toolbarPlugin({
                    toolbarContents: () => (
                      <>
                        <BoldItalicUnderlineToggles />
                        <ListsToggle options={["bullet", "number"]} />
                      </>
                    ),
                  }),
                ]}
              />
            )}

            {questionCategoryEnumId !== 1 && (
              <TextField
                id="infoUrl"
                value={formik.values.infoUrl || ""}
                label="Post-completion URL (optional)"
                error={Boolean(formik.errors.infoUrl)}
                helperText={formik.errors.infoUrl}
                placeholder="https://"
                variant="outlined"
                onChange={formik.handleChange}
                disabled={disabled}
              />
            )}
          </>
        )}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {Boolean(formik.values.questionId) &&
              !disabled &&
              questionCategoryEnumId !== 1 && (
                <>
                  <Typography>Employee evaluation</Typography>
                  <Chip label={`👍 ${question?.positiveFeedback || 0}`} />
                  <Chip label={`👎 ${question?.negativeFeedback || 0}`} />
                </>
              )}
          </Stack>
          <Stack alignItems="flex-end">
            <Tooltip
              title={
                <Stack>
                  <Typography variant="body2">To enable lesson:</Typography>
                  <ul style={{ margin: 0 }}>
                    <li>Fill in the content</li>
                    {questionCategoryEnumId !== 1 &&
                      formik.values.answers &&
                      formik.values.answers.length > 0 &&
                      formik.values.lessonType === LESSON_TYPE.Quiz && [
                        <li key="requiredAnswers">
                          Fill in at least 2 answers
                        </li>,
                        <li key="requiredCorrectAnswers">
                          Mark 1 answer as correct
                        </li>,
                      ]}
                  </ul>
                </Stack>
              }
              disableHoverListener={
                (questionCategoryEnumId !== 1 &&
                  formik.values.lessonType === LESSON_TYPE.Quiz &&
                  formik.values.answers &&
                  formik.values.answers.filter((answer) => answer.text !== "")
                    .length >= 2 &&
                  formik.values.answers
                    .filter((answer) => answer.text !== "")
                    .findIndex((answer) => answer.isCorrect) !== -1 &&
                  formik.values.text !== "") ||
                !formik.values.answers ||
                formik.values.answers.length === 0 ||
                (questionCategoryEnumId === 1 && formik.values.text !== "") ||
                (formik.values.lessonType === LESSON_TYPE.Survey &&
                  formik.values.text !== "")
              }
            >
              <FormControlLabel
                labelPlacement="start"
                sx={{ marginRight: -1 }}
                control={
                  <Switch
                    disabled={
                      disabled ||
                      (questionCategoryEnumId !== 1 &&
                        ((formik.values.answers &&
                          formik.values.answers.length !== 0 &&
                          ((formik.values.lessonType === LESSON_TYPE.Quiz &&
                            formik.values.answers.filter(
                              (answer) => answer.text !== ""
                            ).length < 2) ||
                            formik.values.answers
                              .filter((answer) => answer.text !== "")
                              .findIndex((answer) => answer.isCorrect) ===
                              -1)) ||
                          formik.values.text === "")) ||
                      (questionCategoryEnumId === 1 &&
                        formik.values.text === "")
                    }
                    checked={formik.values.status === QUESTION_STATUSES.Visible}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "status",
                        event.target.checked
                          ? QUESTION_STATUSES.Visible
                          : QUESTION_STATUSES.Invisible
                      );
                    }}
                  />
                }
                label="Enable"
              />
            </Tooltip>
            <Alert
              variant="outlined"
              icon={<InfoCircle />}
              style={{
                border: 0,
                maxWidth: 500,
                alignItems: "center",
                padding: 0,
              }}
            >
              Clicking “Enable” ensures this lesson can later be sent out to
              your teams. If it’s not enabled, nobody will receive this lesson.
              Courses without any enabled lessons cannot be scheduled at all.
            </Alert>
          </Stack>
        </Stack>

        <Stack direction="row" sx={{ pt: 4, pb: 2 }}>
          {Boolean(formik.values.questionId) && (
            <LoadingButton
              disabled={disabled}
              onClick={() => onDelete(formik.values.questionId)}
              variant="outlined"
              startIcon={<Trash width={25} height={25} color="#000" />}
            >
              Delete
            </LoadingButton>
          )}
          <Stack direction="row" justifyContent="flex-end" flex={1}>
            <LoadingButton
              loading={isSubmitting}
              className="lesson-save-button"
              disabled={disabled || !formik.dirty}
              onClick={() => formik.handleSubmit()}
              startIcon={<Save width={25} height={25} color="#fff" />}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </FormikProvider>
  );
};

export default Question;
